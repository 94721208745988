import getNetResource from './netConfig'

const machineData = {
  getJob(args) {
    return getNetResource(`/printer/${args.name}/get-job`, 'get')
  },
  fatalError(args) {
    return getNetResource(`/printer/${args.name}/fatal-error`, 'post', args.data)
  },
  jobFinished(args) {
    return getNetResource(`/printer/${args.name}/job-finished`, 'post', args.data)
  },
  status(args) {
    return getNetResource(`/printer/${args.name}/status`, 'post', args.data)
  },
  getItem(args) {
    return getNetResource(`/printer/${args.name}/get-item`, 'post', args.data)
  },
  getZebraItem(args) {
    return getNetResource(`/printer/${args.name}/get-item-data`, 'post', args.data)
  },
  getBackgroundImages(args) {
    return getNetResource(`/printer/${args.name}/get-background-images`, 'post', args.data)
  },
  getUserImage(args) {
    return getNetResource(`/printer/${args.name}/get-user-image`, 'post', args.data)
  },
  getTemplate(args) {
    return getNetResource(`/printer/${args.name}/get-template/ZebraZC300`, 'post', args.data)
  },
}

export default machineData