import { UserFunctions } from '../loginService'

const dataAid = async ({ fn, args, msg }) => {

  let { success, status, data } = await fn(args)

  if (!success) {
    if (status === 401) {
      let result = await UserFunctions.refreshAccessToken()
      if (result.success) {
        ({ success, status, data } = await fn(args))
      } else {
        console.error(msg)
      }
    } else {
      console.error(msg)
    }
  }
  return { success, status, data }
}

export function debounce(fn, delay) {
  let timeoutID = null
  return function () {
    clearTimeout(timeoutID)
    let args = arguments
    let that = this
    timeoutID = setTimeout(function () {
      fn.apply(that, args)
    }, delay)
  }
}

export default dataAid