import { localData } from '@/storage'
import { SITES } from '@/constants'
import { RepositoryFactory } from '@/repositories/RepositoryFactory'
import dataAid from '@/services/helpers'

const userDataRepository = RepositoryFactory.get('userData')

const handleAuthResponse = (response) => {
  let { token, refreshToken } = response.data
  if (response.status !== 200 || !token || !refreshToken || !refreshToken) {
    return false
  } else {
    localData.set( token, 'token' )
    localData.set( refreshToken, 'refreshToken' )
    return true
  }
}

const UserFunctions = {
  isAuthenticated() {
    let token = localData.get('token')
    return !!token.data && token.data !== 'expired'
  },
  async login({ user, pass, siteName }) {
    let success = false
    let auth = false

    const response = await userDataRepository.authenticate({ user, pass, siteName })
    if(response.success){
      auth = true
      localData.set( response.data.token, 'token' )
      localData.set( response.data.refreshToken, 'refreshToken' )
      localData.set( response.data.userUuid, 'uuid' )
      localData.set( user, 'userName' )
      success = true
    }
    else if(response.status===428)
      auth = true
    return { success, auth }
  },
  async activate(passData) {
    return dataAid({
      fn: userDataRepository.activateAccount, args: passData,
      msg: 'Problemas con el cambio de contraseña.'
    })
  },
  findHome(siteName) {
    return siteName ? SITES[siteName].home : ''
  },
  getSiteNameFromFolder(folder) {
    let site = ''
    for (const siteName in SITES) {
      if ( SITES[siteName] && SITES[siteName].name === folder ) {
        site = siteName
      }
    }
    return site
  },
  async refreshAccessToken() {
    let isAuthenticated = false
    let refreshToken = localData.get('refreshToken')
    let token = localData.get('token')
  
    if (refreshToken && token) {
      const response = await userDataRepository.sendRefreshToken({refreshToken: refreshToken.data, token: token.data})
      if(response.success)
        isAuthenticated = handleAuthResponse(response)
      else {
        this.logout()
      }
    }
    return { success: isAuthenticated }
  },
  async sendNewPass(email,userType) {
    let success = false
    const response = await userDataRepository.sendNewPass(email,userType)
    if(response.success)
      success = true
    else
      console.error('Problemas con el servicio de envio de emails.')
    return success
  },
  async sendNewPassFn(args) {
    return await dataAid({
      fn: userDataRepository.sendNewPassFn, args,
      msg: 'Problemas al enviar nueva contraseña.'
    })
  },
  logout() {
    localData.clear()
  }
}

export { UserFunctions }
