import Repository from './Repository'
import {localData} from '@/storage'

const BASE = '/api'
const VERSION = '/v1'
const WHITELIST = [
  '/authenticate', '/passwords/sendTemporaryUserPassword',
  '/passwords/changeUserPassword', '/refresh-token'
]
const CARDWHITELIST = [
  'getUserInfo', 'getCurrent', 'getBarcode',
  'getQr', 'get-all', 'getUserInfo'
]
const CARDWHITELISTDIR = ['templates', 'font-files', 'users']

const responseError = (error) => {
  let status, data
  if (error.response) {
    status = error.response.status
    data = error.response.data?.message
  } else {
    status = 500
    data = ''
  }
  return { success: false, status, data }
}

const getHeaders = () => {
  let token = localData.get('token')
  return token.data && token.data !== 'expired' ?
    { headers: { 'Authorization': `Bearer ${token.data}` }} : undefined
}

const getNetResource = async ( url, type, args ) => {
  let headers = getHeaders()
  let response = { success: false, status: 'expired', data: null }
  let whitelisted = WHITELIST.includes(url)
  let whitelistedForPrinting = false

  if (!whitelisted) {
    let dirs = url.split('/')
    if (dirs.length > 1 && CARDWHITELISTDIR.includes(dirs[1])) {
      whitelistedForPrinting = CARDWHITELIST.includes(dirs[2])
    }
  }
  if ( whitelistedForPrinting || whitelisted || headers) {
    headers = whitelisted ? null : headers
    let repArgs = type === 'get' ?
      [ `${BASE}${VERSION}${url}`, headers ] 
      : [ `${BASE}${VERSION}${url}`, args, headers ]
    response = Repository[type](...repArgs )
      .then((res) => { return { ...res, success: true } })
      .catch((error) => { return responseError(error) })
  }
  return response
}

export default getNetResource