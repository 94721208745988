import getNetResource from './netConfig'

const printerData = {
  archive(args) {
    return getNetResource('/prints/archive', 'post', args)
  },
  createMachineUser(args) {
    const formData = new FormData()
    formData.append('accountName', args.accountName)
    formData.append('password', args.password)
    return getNetResource('/prints/add-machine-user', 'post', formData)
  },
  createPrinterUser(user) {
    return getNetResource('/prints/add-printer-user', 'post', user)
  },
  deleteMachineUser(user) {
    return getNetResource('/prints/delete-machine-user', 'post', user)
  },
  deletePrinterUser(user) {
    return getNetResource('/prints/delete-printer-user', 'post', user)
  },
  disablePrinterUser(user) {
    return getNetResource('/prints/disable-printer-user', 'post', user)
  },
  createPrinter(args) {
    return getNetResource('/prints/add-printer', 'post', args)
  },
  editPrinter(args) {
    return getNetResource('/prints/edit-printer', 'post', args)
  },
  enablePrinterUser(args) {
    return getNetResource('/prints/enable-printer-user', 'post', args)
  },
  deletePrinter(args) {
    return getNetResource('/prints/delete-printer', 'post', args)
  },
  finishPrintWork(args) {
    return getNetResource('/prints/finish-print-work', 'post', args)
  },
  getListPrint(config) {
    return getNetResource('/prints/get-list-print', 'post', config)
  },
  getListPrintWorkItems(config){
    const formData = new FormData()
    formData.append('id', config.id)
    formData.append('status', config.status)
    formData.append('filter', config.filter)
    formData.append('nPage', config.nPage)
    formData.append('pageSize', config.pageSize)
    return getNetResource('/prints/details', 'post', formData)
  },
  getAmountPrintWorkItems(config){
    const formData = new FormData()
    formData.append('id', config.id)
    formData.append('status', config.status)
    formData.append('filter', config.filter)
    return getNetResource('/prints/get-amount-items', 'post', formData)
  },
  getPrintersData() {
    return getNetResource('/prints/get-printers', 'get')
  },
  getPrinterUsers(filter) {
    return getNetResource(`/prints/get-printer-users/${filter}`, 'get')
  },
  getMachineUsers() {
    return getNetResource('/prints/get-machine-users', 'get')
  },
  printBatch(args) {
    return getNetResource('/prints/print-items', 'post', args)
  },
  printCard(args) {
    return getNetResource('/prints/print-items', 'post', args)
  },
  recover(args) {
    return getNetResource('/prints/recover', 'post', args)
  },
  removeFromQueue(args) {
    return getNetResource('/prints/remove-print-job', 'post', args)
  },
  stopCancelPrint(args) {
    return getNetResource('/prints/toggle-print-job', 'post', args)
  },
  stopPrintWork({ printerName, printJob, status }) {
    let res = status === 'QUEUED' ? '/prints/delete-print-job' : '/prints/toggle-print-job'
    return getNetResource(res, 'post', { printerName, printJob })
  },
}

export default printerData