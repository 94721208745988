import { createRouter, createWebHashHistory } from 'vue-router'
const signin = () => import('@/components/SignIn.vue')
const companyHome = () => import('@/components/enterprise/Home.vue')
const employeeHome = () => import('@/components/employee/Home.vue')
const forgotPass = () => import('@/components/ForgotPass.vue')
const guardHome = () => import('@/components/guard/Home.vue')
const machineHome = () => import('@/components/machine/Home.vue')
const approverHome = () => import('@/components/approver/Home.vue')
const superadminHome = () => import('@/components/superadmin/Home.vue')
const cardPrevisualization = () => import('@/components/employee/Previsualization.vue')
const printerHome = () => import('@/components/printer/Home.vue')
const rrhh = () => import('@/components/employee/RRHH.vue')
const showId = () => import('@/components/employee/ShowId.vue')
const showCard = () => import('@/components/printer/ShowCard.vue')
const Home = () => import('@/components/Home.vue')
import { SITES, BASEPATH } from '@/constants'
import { UserFunctions } from '@/services/loginService'


// ------ TODO ----------
// Permitir el login offline para empleados solamente,
// de manera de poder recuperar la credencial

const allSites = Object.values(SITES)
  .reduce(( siteFolderAcc, siteFolder ) => {
    return siteFolderAcc ? `${siteFolderAcc}|${siteFolder.name}` : siteFolder.name
  }, '')

const router = createRouter({
  history: createWebHashHistory(),
  routes: [
    {
      name: 'Home',
      path: `${BASEPATH}`,
      components: {default: Home},
      meta: {
        requiresAuth: false,
        site: 'HOME'
      }
    },
    {
      name: 'login',
      path: `${BASEPATH}:siteFolder(\\${allSites})/login`,
      components: {default: signin},
      meta: {
        guest: true
      }
    },
    {
      name: 'forgotPass',
      path: `${BASEPATH}:siteFolder(\\${allSites})/forgotPass`,
      components: {default: forgotPass},
      meta: {
        guest: true
      }
    },
    {
      name: 'employeeHome',
      path: `${BASEPATH}${SITES.EMPLOYEE.name}/home`,
      components: {default: employeeHome},
      meta: {
        requiresAuth: true,
        site: 'EMPLOYEE'
      }
    },
    {
      name: 'guardHome',
      path: `${BASEPATH}${SITES.GUARD.name}/home`,
      components: {default: guardHome},
      meta: {
        requiresAuth: true,
        site: 'GUARD'
      }
    },
    {
      name: 'preview',
      path: `${BASEPATH}${SITES.EMPLOYEE.name}/preview`,
      components: {default: cardPrevisualization},
      meta: {
        requiresAuth: true,
        site: 'EMPLOYEE'
      }
    },
    {
      name: 'rrhh',
      path: `${BASEPATH}${SITES.EMPLOYEE.name}/rrhh`,
      components: {default: rrhh},
      meta: {
        requiresAuth: true,
        site: 'EMPLOYEE'
      }
    },
    {
      name: 'showId',
      path: `${BASEPATH}${SITES.EMPLOYEE.name}/Id`,
      components: {default: showId},
      meta: {
        requiresAuth: true,
        site: 'EMPLOYEE'
      }
    },
    {
      name: 'companyHome',
      path: `${BASEPATH}${SITES.ENTERPRISE.name}/home`,
      components: {default: companyHome},
      meta: {
        requiresAuth: true,
        site: 'ENTERPRISE'
      }
    },
    {
      name: 'superadminHome',
      path: `${BASEPATH}${SITES.SUPERADMIN.name}/home`,
      components: {default: superadminHome},
      meta: {
        requiresAuth: true,
        site: 'SUPERADMIN'
      }
    },
    {
      name: 'approverHome',
      path: `${BASEPATH}${SITES.APPROVER.name}/home`,
      components: {default: approverHome},
      meta: {
        requiresAuth: true,
        site: 'APPROVER'
      }
    },   
    {
      name: 'printerHome',
      path: `${BASEPATH}${SITES.PRINTER.name}/home`,
      components: {default: printerHome},
      meta: {
        requiresAuth: true,
        site: 'PRINTER'
      }
    },
    {
      name: 'machineHome',
      path: `${BASEPATH}${SITES.MACHINE.name}/home`,
      components: {default: machineHome},
      meta: {
        requiresAuth: true,
        site: 'MACHINE'
      }
    },
    {
      name: 'getCard',
      path: `${BASEPATH}${SITES.PRINTER.name}/getCard/:side/:uuid`,
      components: {default: showCard},
      props: {default: true},
      meta: {
        noMenu: true,
        requiresAuth: false,
        site: 'PRINTER'
      }
    },
  ]
})

router.beforeEach(( to, from, next ) => {
  if ( to.matched.some( record => record.meta.requiresAuth )) {
    if (!UserFunctions.isAuthenticated()) {
      next({
        name: 'login',
        params: { nextUrl: to.fullPath, siteFolder: SITES[to.meta.site].name }
      })
    } else {
      next()
    }
  } else {
    if (!UserFunctions.isAuthenticated()) {
      next()
    } else {
      if (to.name === 'login') {
        let siteName = UserFunctions.getSiteNameFromFolder(to.params.siteFolder)
        let name = SITES[siteName] ? SITES[siteName].home : ''
        next({name})
      } else {
        next()
      }
    }
  }
})

export default router