import getNetResource from './netConfig'
const templateData = {
  create(newTemplate) {
    return getNetResource('/templates/createNew', 'post', newTemplate)
  },
  createBg(newBg) {
    let formData = new FormData()
    formData.append('background', newBg.bgImage)
    formData.append('templateId', newBg.templateId)
    formData.append('templateName', newBg.templateName)
    formData.append('face', newBg.face)
    return getNetResource('/templates/createNewBackgroundImage', 'post', formData)
  },
  delete(template) {
    return getNetResource('/templates/delete', 'post', template)
  },
  deleteFont(args) {
    return getNetResource('/font-files/delete', 'post', args)
  },
  update(template) {
    return getNetResource('/templates/update', 'post', template)
  },
  getFonts() {
    return getNetResource('/font-files/get-all', 'get')
  },
  getTemplateData(templateId) {
    return getNetResource(`/templates/getCurrent/${templateId}`, 'get')
  },
  getTemplateList() {
    return getNetResource('/templates/getAll', 'get')
  },
  getTemplateFields(templateId) {
    return getNetResource(`/templates/getFields/${templateId}`, 'get')
  },
  reLink(config) {
    return getNetResource('/templates/re-link', 'post', config)
  },
  saveFont(args) {
    let formData = new FormData()
    formData.append('family', args.family)
    formData.append('source', args.source)
    return getNetResource('/font-files/save', 'post', formData)
  },
}

export default templateData
