
const namesStorage = {
  token: 't8k6ehnq',
  userInfo: 'akskak5',
  templateData: 'template',
  fontsList: 'fontsList',
  uuid: 'i3qd2w',
  userName: 'i3qd2q',
  ts: 'ts'
}
const TTL = 24 * 60 * 60 * 1000
const lastActivityTimestamp = () => {
  const now = new Date()
  localStorage[getStorageKey('ts')] = now.getTime() + TTL
}
const getStorageKey = (name) => {
  return namesStorage[name] ? namesStorage[name] : name
}
const getDataFromLS = ( where, id ) => {
  let data
  id = id ? id : ''
  try {
    data = JSON.parse(localStorage[getStorageKey(where) + id])
  } catch(e) {
    data = localStorage[getStorageKey(where) + id]
  }
  return data ? data : { data: null, creationDate: 0 }
}

export const localData = {
  set( what, where, id ) {
    let serializedWhat
    const now = new Date()
    let timestampedData = { data: what, creationDate: now.getTime() }
    id = id ? id : ''
    serializedWhat = JSON.stringify(timestampedData)
    // Cada vez que se setea una propiedad, se asume usuario logueado y se extiende el tiempo
    // Una posibilidad es setarlo solo cuando el usuario se loguea,  (where === 'token') ~alvaro
    lastActivityTimestamp()

    localStorage[getStorageKey(where) + id] = serializedWhat
  },
  get( where, id ) {
    let data = 'expired'
    let creationDate = 0
    const now = new Date()
    if ( !navigator.onLine && now.getTime() > localStorage[getStorageKey('ts')] ) {
      this.clear()
    } else {
      ({ data, creationDate } = getDataFromLS( where, id ))
    }

    return { data, creationDate }
  },
  clear() {
    return localStorage.clear()
  },
  remove(where, id ) {
    return localStorage.removeItem(getStorageKey(where) + id)
  },
}