
<template>
  <div class="app-body">
    <navBar id="navbar" :expired="isExpired" :tokenData="tokenData" />
    <div class="content">
      <!-- TODO: tal vez sea necesario agregar keep-alive -->
      <router-view />
    </div>
  </div>
</template>
<script>
import '@/assets/css/custom.scss'
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import '@/assets/css/main.scss'
import 'vue-toastification/dist/index.css'

import navBar from './components/Navbar.vue'
import { UserFunctions } from '@/services/loginService'
import { jwtDecode } from "jwt-decode";

export default {
  name: 'App',
  components: { navBar },
  provide() {
    return { expire: this.expire, dataToken: this.dataToken}
  },
  data() {
    return {
      isExpired: false,
      tokenData: null
    }
  },
  methods: {
    expire(e) {
      this.isExpired = e
    },
    dataToken(data){
      this.tokenData = data
    }
  },
  mounted(){
    //Obtener token
    const token = localStorage.getItem('t8k6ehnq');
      
    if (token) {
      const parsedToken = JSON.parse(token);
      const jwt = parsedToken.data;
      this.tokenData = jwtDecode(jwt);
      
      const landingPage = UserFunctions.findHome(this.tokenData.usrType)
      this.$router
      .push({name: landingPage})
      .catch( error => {
        console.error(error.message)
      })
  
    }
  }
}
</script>